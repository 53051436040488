@import '../../../colors';

.FormContent {
  position: relative;

  .checkbox-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px 0;

    label {
      margin-left: 8px;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .input-spacing-container {
    margin: 12px 0;
  }

  .error-message-container {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    font-size: 14px;
    padding: 12px;
    text-align: center;

    p {
      margin: 0;
    }
  }

  .action-buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;

    button {
      background-color: rgba(0, 0, 0, 0);
      border: 1px solid;
    }
  }
}
