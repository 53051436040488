@import '../../colors';

.FormNotFound {
  position: relative;
  text-align: center;
  padding: 40px 0;

  .inner {
    width: 630px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 670px) {
      width: 100%;
      padding: 0 20px;
    }

    h1 {
      font-size: 2em;
      font-weight: 500;
      color: $textColor;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.43;
      letter-spacing: .01em;
      color: $textColor;
    }

    img {
      width: 100%;
    }
  }
}
