@import '../../../../colors';

.FormDateInput {
  position: relative;
  
  .date-inputs-container {
    width: 220px;
    display: flex;
    flex-direction: row;

    .date-divider {
      color: rgba(0, 0, 0, 0.2);
      border-top: 2px solid ;
      border-bottom: 2px solid;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 20px;
    }

    .month,
    .day {
      flex: 2;
    }

    .month {
      border-radius: 4px 0 0 4px;
      border-right: none;
    }

    .day {
      border-radius: 0;
      border-left: none;
      border-right: none;
    }

    .year {
      flex: 3;
      border-left: none;
      border-radius: 0 4px 4px 0;
    }
  }
}
