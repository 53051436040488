@import "../../colors";

.Auth {
  min-height: 100vh;
  padding: 50px 0;

  .form-container {
    max-width: 600px;
    padding: 70px 20px;
    margin: auto;

    .auth-well {
      background-color: $backgroundColor;

      .message {
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 40px;
        color: $textColor;
      }

      h1 {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin: 20px 0 20px;
        color: $textColor;
      }

      label {
        color: $textColor;
      }

      .error-message {
        margin: 0;
        color: $primaryColor;
        font-size: 12px;
        margin: -7px 0;
      }

      button {
        width: 100%;
        margin-top: 20px;
      }
    }

    a {
      font-size: 12px;
      color: $linkColor;
      text-decoration: underline;
    }

    .forgot-password-link-container {
      text-align: center;
      padding: 12px 0;
      cursor: pointer;
    }

    .or-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .or-divider {
        border-bottom: 1px solid $borderDark;
        flex: 1;
      }

      p {
        margin: 10px 15px;
        font-size: 14px;
        color: $textColor;
      }
    }

    .create-account-button-container {
      button {
        margin-top: 15px;
      }
    }

    .terms-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 15px 0;

      .terms-container-inner {
        display: flex;
        flex-direction: row;
        align-items: center;

        .terms-divider {
          margin: 0 10px;
          font-size: 12px;
          color: $textColor;
        }
      }
    }

    .sign-in-container {
      font-size: 12px;
      text-align: center;
      padding: 12px 0;
      color: $textColor;

      a {
        cursor: pointer;
      }
    }

    .sign-up-terms-container {
      margin: 0;
      text-align: center;
      font-size: 12px;
      color: $textColor;
    }

    .copyright-container {
      text-align: center;

      p {
        color: $textColor;
        font-size: 12px;
        margin: 25px 0 5px;
      }
    }
  }
}
