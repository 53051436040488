@import '../../colors';

.Form {
  position: relative;

  .form-submitted {
    position: relative;
    text-align: center;
    padding: 40px 0;
  
    .inner {
      width: 630px;
      margin-left: auto;
      margin-right: auto;
  
      @media (max-width: 670px) {
        width: 100%;
        padding: 0 20px;
      }
  
      h1 {
        font-size: 2em;
        font-weight: 500;
        color: $textColor;
      }
  
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.43;
        letter-spacing: .01em;
        color: $textColor;
      }
  
      img {
        width: 100%;
      }
    }
  }

  .full-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 70px 10px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center top;
    background-size: cover;

    @media (max-width: 850px) {
      padding: 40px 10px;
    }

    @media (max-width: 750px) {
      padding: 10px;
    }

    .form-container {
      margin-left: auto;
      margin-right: auto;
      padding: 20px;
      width: 750px;
      border-radius: 4px;
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center top;
      background-size: cover;

      @media (max-width: 770px) {
        width: 100%;
      }
    }

    &.disable-padding {
      padding: 0;

      .form-container {
        padding: 0;
        width: 840px;

        @media (max-width: 860px) {
          width: 100%;
        }
      }
    }
  }

  .split-container {
    display: flex;

    .form-container {
      flex: 1;

      .form-inner {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 50px 20px;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center top;
        background-size: cover;
      }
    }

    .side-container {
      flex: 1;
      height: 100vh;
      position: relative;

      .side-container-inner {
        position: fixed;
        height: 100%;
        width: 50%;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center top;
        background-size: cover;
      }
    }
  }

  .full {

    .split-container {
      display: none;
    }
  }

  .split {

    .full-container {
      display: none;
    }

    @media (max-width: 850px) {
      .full-container {
        display: flex;
      }

      .split-container {
        display: none;
      }
    }
  }
}
