.FormFileUpload {
  position: relative;

  .file-row {
    border: 1px solid #592E2C;
    border-radius: 4px;
    padding: 10px 12px;
    margin-top: 10px;
    background-color: #fafafa;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
    }

    button {
      padding: 5px 10px;
    }

    .delete-button {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 30px;
      width: 30px;
      padding: 0;

      img {
        height: 15px;
      }
    }

    .deleting-indicator-container {
      background-color: #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 30px;
      width: 30px;
      padding: 0;
      border-radius: 15px;

      img {
        height: 16px;
        filter: brightness(0);
      }
    }

    &.error-message-container {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;

      button {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 30px;
        width: 30px;
        padding: 0;

        img {
          filter: brightness(0) invert(1);
          height: 15px;
        }
      }
    }
  }
}
